import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
  PrimaryButton,
  Loader,
  Alert,
  MarkupGenerator,
} from "../../components/Elements/elements";
import { ENTITY_FIELDS } from "../../graphql/Schemas/Queries/Queries";
import { useFields } from "../../utils/useFields";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ENTITY_FIELDS_MUTATION } from "../../graphql/Schemas/Mutations/Mutations";
import { useLocation, useHistory } from "react-router";
import { useState } from "react";
import { Card, CardContent } from "@material-ui/core";

export const PalyazatJelentesPage = () => {
  const [messages, setMessages] = useState("");
  const { state } = useLocation();
  const history = useHistory();
  if (!state) {
    history.push("/");
  }
  const { ModifyData, Fields, getYupSchema } = useFields;
  const { loading, data } = useQuery(ENTITY_FIELDS, {
    variables: {
      bundle: state?.bundle,
      entityType: state?.entityType,
      id: state?.new ? "" : state?.id,
    },
    fetchPolicy: "no-cache",
  });
  const [editData, { loading: loadingMutation }] = useMutation(
    ENTITY_FIELDS_MUTATION
  );
  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    setFocus,
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    resolver: yupResolver(
      yup.object().shape(getYupSchema(data?.getEntityFields)),
      { abortEarly: false }
    ),
  });
  const onSubmit = (dataForm) => {
    const { input } = ModifyData(dataForm);

    setMessages("");
    const newTender = state.new
      ? [{ name: "field_tender_ref", value: `${state.id}` }]
      : [];

    editData({
      variables: {
        input: [...input, ...newTender],
        bundle: state?.bundle,
        entityType: state?.entityType,
        id: !state.new ? String(state?.id) : "",
      },
    }).then(({ data: { setEntityFields } }) => {
      let focus = setEntityFields?.errors?.find(
        ({ propertyPath }) => propertyPath != null
      );
      if (focus != undefined) {
        setFocus(focus.propertyPath);
      } else {
        window.scrollTo(0, 550);
      }
      setEntityFields.errors?.forEach(({ message, propertyPath }) => {
        if (propertyPath) {
          setError(propertyPath, {
            type: "manual",
            message,
          });
        } else {
          setMessages((el) => `${el + message + "<br>"}`);
        }
      });
      if (setEntityFields?.success != "FALSE") {
        history.push({
          pathname: "/",
          state: { message: setEntityFields.success },
        });
      }
    });
  };

  return (
    <div className="tender">
      <h2 className="breki-title">
        {state?.title || "Ösztöndíj Pályázat beküldése"}
      </h2>
      <div className="card" style={{ textAlign: "justify" }}>
        <div className="card-title">Figyelem</div>
        {/* <br />
        A rendszer csak lezárt pályázati űrlap nyomtatását engedélyezi.
        <br /> */}
        {/* <br />
        A pályázati űrlap lezárása előtt mindenképpen ellenőrizd le, hogy minden
        adatot pontosan írtál-e be, mert az űrlap tartalma a lezárás után már
        nem módosítható.
        <br /> */}
        <br />
        A pályázati űrlap beküldése előtt mindenképpen ellenőrizd le, hogy
        minden adatot pontosan írtál-e be, mert az űrlap tartalma a beküldés után
        már nem módosítható.
        <br />
        {/* <br />
        Az űrlap lezárása csak az összes kötelező mező kitöltése és az összes
        kötelezően mellékelendő dokumentum feltöltése után lehetséges.
        <br />
        <br />A pályázat beadása után két kinyomtatott, aláírt példányt, a
        kötelező mellékletekkel együtt személyesen is át kell adnod a
        legközelebbi CMH irodában. */}
      </div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Card className="tender-wrapper">
          <CardContent>
            {messages && (
              <div className="big-alert">
                <Alert
                  text={<MarkupGenerator string={messages} />}
                  color="error"
                  close={setMessages}
                />
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Fields
                control={control}
                register={register}
                errors={errors}
                data={data?.getEntityFields}
                unregister={unregister}
                setValue={setValue}
                disabled={state?.disabled}
              />
              {data &&
                !(state?.status >= 3) &&
                state?.disabled == undefined && (
                  <PrimaryButton
                    type="submit"
                    disabled={Object.keys(errors).length || loadingMutation}
                    text={"Pályázat mentése"}
                  />
                )}
              <br />

              {state?.bundle == 'kelep_high_school' && (
                <div className="card-text">A pályázat mentésével a pályázatot még nem nyújtotta be. Kérjük a főoldalon, a feladataim résznél véglegesítse az űrlapot, majd kinyomtatva, aláírva továbbítsa kollégáinknak.</div>
                )}
            </form>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
